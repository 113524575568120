<template>
	<TheArtGrid feedName="all" />
</template>

<script>
import TheArtGrid from '@/components/TheArtGrid'

export default {
	name: 'AdminFeed',
	components: {
		TheArtGrid,
	},
}
</script>

<style lang="scss" scoped></style>
